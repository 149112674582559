import { DefaultTheme } from 'styled-components';

// DefaultTheme is declared and extended in 'styled.d.ts'
export const theme: DefaultTheme = {
  palette: {
    text: {
      light: '#bdbdbd',
      main: '#333333',
      secondary: '#5F7091',
    },
    primary: {
      light: '#E6F0FF',
      main: '#0060FF',
      textContrast: '#ffffff',
    },
    error: {
      main: '#cc0044',
      textContrast: '#ffffff',
    },
    background: {
      primary: '#ffffff',
      secondary: '#F4F5FB',
    },
  },
  fontSize: {
    large: '2.4rem',
    regular: '1rem',
    small: '0.9em',
  },

  primaryColor: '#001f66',
  darkBg: '#051e39',
  lightBg: '#e5f0fe',
  round: '8px',
  'round-sm': '4px',
};
